
import { Component, Vue } from 'vue-property-decorator'
import Logo from "@/components/Logo/index.vue"

@Component({
  name: 'ProductFinder',
  components: {
    Logo
  },
})
export default class extends Vue {
  products: {src: string, alt: string, url: string}[] = [
    {
      src: "gud_logo.png",
      alt: "GUD",
      url: "https://www.gud.co.za/filter-finder/"
    },
    {
      src: "fram_logo.jpg",
      alt: "Fram",
      url: "https://www.fram.co.za/parts-search/"
    },
    {
      src: "safeline_logo.png",
      alt: "Safeline",
      url: "https://safelinebrakes.co.za/part-finder/"
    },
    {
      src: "holts_logo.jpg",
      alt: "Holts",
      url: "https://holtssa.co.za/"
    },
    {
      src: "indyoil_logo.gif",
      alt: "IndyOil",
      url: "http://www.indyoil.co.za"
    },
    {
      src: "raw_logo.png",
      alt: "RAW 4x4",
      url: "https://raw4x4.co.za/"
    },
    {
      src: "powerdown_logo.png",
      alt: "Powerdown",
      url: "https://powerdown.co.za/"
    }
  ]
}
