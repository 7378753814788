
import Logo from "@/components/Logo/index.vue"
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'
import { Form as ElForm, Input } from 'element-ui'
import { isValidUsername } from '@/utils/validate'
import { AuthModule } from '@/store/modules/auth'
import store from '@/store'
import { RegisterDto } from "@/api-client"
import handleError from "@/utils/handleError";

interface RegisterForm extends RegisterDto {
  passwordConfirm: string
}

@Component({
  name: 'Register',
  components: {
    Logo
  }
})
export default class extends Vue {
  private regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(.{8,})$/
  // private validateUsername = (rule: any, value: string, callback: Function) => {
  //   if (this.registerForm.firstName.length < 1 || this.registerForm.lastName.length < 1) {
  //     callback(new Error('Username is required'))
  //   }
  //   else {
  //     callback()
  //   }
  // }

  private validateCellNumber = (rule: any, value: string, callback: Function) => {
    if (value.length < 1) {
      callback(new Error('Cell number is required'))
    }
    else {
      callback()
    }
  }

  private validateWorkshop = (rule: any, value: string, callback: Function) => {
    if (value.length < 1) {
      callback(new Error('Workshop name is required'))
    }
    else {
      callback()
    }
  }

  private validateEmail = (rule: any, value: string, callback: Function) => {
    if (value.length < 1) {
      callback(new Error('Email is required'))
    }
    else {
      callback()
    }
  }

  private validatePassword = (rule: any, value: string, callback: Function) => {
    const isPassword = this.regexPassword.test(value)
    if (value.length < 1) {
      callback(new Error('Password is required'))
    }
    else if (!isPassword) {
      callback(new Error('Password must have at least 8 characters, 1 uppercase character, 1 lowercase character, 1 symbol and 1 number.'))
    } else {
      (this.$refs.registerForm as ElForm).validateField('passwordConfirm')
      callback()
    }
  }

  private validatePasswordConfirm = (rule: any, value: string, callback: Function) => {
    if (this.registerForm.password !== this.registerForm.passwordConfirm) {
      callback(new Error('The passwords do not match'))
    } else {
      callback()
    }
  }

  registerForm:RegisterForm = {
    firstName: '',
    lastName: '',
    // displayName: '',
    cellNumber: '',
    workshopName: '',
    email: '',
    password: '',
    passwordConfirm: '',
    acceptedPrivicyPolicy: false
  }

  instructions = [
    {
      icon: 'form',
      copy: 'Fill out the form'
    },
    {
      icon: 'messagebox',
      copy: 'Keep an eye out for a request sent to your email for you to validate your profile'
    },
    {
      icon: 'user',
      copy: 'Go to the login page and use your new account credentials'
    },
    {
      icon: 'trophy',
      copy: 'You\'re in!'
    }
  ]

  private registerRules = {
    // displayName: [{ validator: this.validateUsername, trigger: 'change' }],
    cellNumber: [{ validator: this.validateCellNumber, required: true, trigger: 'change' }],
    workshopName: [{ validator: this.validateWorkshop, required: true, trigger: 'change' }],
    email: [{ validator: this.validateEmail, required: true, type: 'email', trigger: 'change' }],
    password: [{ validator: this.validatePassword, required: true, regex: this.regexPassword, trigger: 'change' }],
    passwordConfirm: [{ validator: this.validatePasswordConfirm, required: true, trigger: 'change' }]
  }

  private passwordType = 'password'
  private passwordConfirmType = 'password'
  private loading = false
  private showDialog = false
  private redirect?: string
  private otherQuery: Dictionary<string> = {}

  @Watch('$route', { immediate: true })
  private onRouteChange(route: Route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>
    if (query) {
      this.redirect = query.redirect
      this.otherQuery = this.getOtherQuery(query)
    }
  }

  mounted() {
    (this.$refs.firstName as Input).focus()
  }

  // updateDisplayName() {
  //   this.registerForm.displayName = `${this.registerForm.firstName} ${this.registerForm.lastName}`
  // }

  private showPwd(isConfirm: boolean) {
    if(!isConfirm) {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
    }
    else {
      if (this.passwordConfirmType === 'password') {
        this.passwordConfirmType = ''
      } else {
        this.passwordConfirmType = 'password'
      }
    }
    this.$nextTick(() => {
      if(!isConfirm) {
        (this.$refs.password as Input).focus()
      }
      else {
        (this.$refs.passwordConfirm as Input).focus()
      }
    })
  }

  private async handleRegister() {
    (this.$refs.registerForm as ElForm).validate(async(valid: boolean) => {
      if(valid) {
        this.loading = true
        await store.dispatch("auth/register", {
          firstName: this.registerForm.firstName,
          lastName: this.registerForm.lastName,
          // displayName: this.registerForm.displayName,
          cellNumber: this.registerForm.cellNumber,
          workshopName: this.registerForm.workshopName,
          email: this.registerForm.email,
          password: this.registerForm.password,
          acceptedPrivicyPolicy: this.registerForm.acceptedPrivicyPolicy
        } as RegisterDto)
        .then((res) => {
          this.loading = false
          this.$message({showClose: true, type: 'success', duration: 2000, message: 'Registration successful!'})
          this.$router.push({name: 'Login'})
        })
        .catch((error) => {
          this.loading = false;
          handleError(error);
        })
      }
    })
  }

  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur]
      }
      return acc
    }, {} as Dictionary<string>)
  }
}
